<template>
  <v-container>
    <base-material-card icon="mdi-account" title="Сотрудники" class="px-5 py-3">
      <v-tabs class="mb-3">
        <v-tab @click="deletedFilter(null)">Активные</v-tab>
        <v-tab @click="deletedFilter('1')">Не активные</v-tab>
      </v-tabs>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        item-key="id"
        :loading="loading"
        loading-text="Идёт загрузка данных..."
        :options.sync="options"
        :server-items-length="totalItems"
      >
        <template v-slot:[`item.role`]="{ item }">
          <div>
            {{ set_role(item) }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mr-2"
            color="red darken-2"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <v-btn color="primary" text @click="add">
        Добавить
      </v-btn>
    </base-material-card>
  </v-container>
</template>

<script>
import { UsersApiUrls } from "@/services/accommodationRequests/user.api.js";
import { FOOD_TICKET_MANAGER } from "@/shared/config";

import toast from "../../../plugins/toast";

export default {
  name: "Employers",
  data: () => ({
    deleted: null,
    firstWatcherTrigger: false,
    totalItems: 0,
    options: {},
    headers: [
      {
        text: "Фамилия",
        value: "last_name",
        class: "table-title",
        sortable: false
      },
      {
        text: "Имя",
        value: "first_name",
        class: "table-title",
        sortable: false
      },
      { text: "Роль", value: "role", class: "table-title", sortable: false },
      { text: "e-mail", value: "email", class: "table-title", sortable: false },
      {
        text: "Действия",
        value: "actions",
        class: "table-title",
        sortable: false
      }
    ],
    items: [],
    loading: false
  }),
  created() {
    this.loadItems();
  },
  watch: {
    options: {
      handler() {
        if (!this.firstWatcherTrigger) {
          this.firstWatcherTrigger = true;
          return;
        }
        this.loadItems();
      },
      deep: true
    }
  },
  methods: {
    deletedFilter(action) {
      this.deleted = action;
      this.options.page = 1;
      this.loading = true;
      this.loadItems();
    },
    set_role(item) {
      let obj = {
        admin: "Суперадмин",
        merchant_admin: "Админ",
        merchant: "Сотрудник",
        [FOOD_TICKET_MANAGER]: "Сотрудник QR"
      };
      return obj[item.user_role];
    },
    async loadItems() {
      this.loading = true;
      this.items = [];
      let params = {
        "page[number]": this.options.page || 1,
        "page[size]": this.options.itemsPerPage || 10
      };
      if (this.deleted) {
        params["is_active"] = "false";
      } else {
        params["is_active"] = "true";
      }
      let res = await UsersApiUrls.getEmployees(params);
      this.totalItems = res.meta.pagination.count;
      res.results.map(el => this.items.push(el));
      this.loading = false;
    },
    editItem(item) {
      this.$router.push("/employee/" + item.id);
    },
    async deleteItem(item) {
      await UsersApiUrls.deleteEmployee(item.id);
      this.loadItems();
      toast.success("Успешно удален!");
    },
    add() {
      this.$router.push("/employee");
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: orange !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
</style>
